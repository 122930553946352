import { gsap } from "gsap";

export function accordion() {
  const accordionGsap = () => {
    const accordions = document.querySelectorAll(".js-accordion");
    const isOpen = "is-open"; // アイコンの切り替え用クラス
  
    accordions.forEach((accordion) => {
      const summary = accordion.querySelector(".js-accordion-summary");
      const content = accordion.querySelector(".js-accordion-content");
  
      summary.addEventListener("click", (event) => {
        if (window.innerWidth >= 768) {
          // PCビューではアコーディオンを動作させない
          event.preventDefault();  // リンクなどのデフォルトの動作をキャンセル
          return;
        }
  
        event.preventDefault();
  
        if (accordion.classList.contains(isOpen)) {
          accordion.classList.remove(isOpen);
          content.classList.remove(isOpen);
          summary.classList.remove(isOpen);
          closeAnimation(content, accordion).restart();
        } else {
          accordion.classList.add(isOpen);
          content.classList.add(isOpen);
          summary.classList.add(isOpen);
          accordion.setAttribute("open", "");
          openAnimation(content).restart();
        }
      });
    });
  };

  // アコーディオンが開くときのGSAPアニメーション
  const openAnimation = (content) =>
    gsap.fromTo(
      content,
      {
        height: 0,
      },
      {
        height: "auto",
        duration: 0.3,
        ease: "power2.out",
        overwrite: true,
      }
    );

  // アコーディオンが閉じるときのGSAPアニメーション
  const closeAnimation = (content, element) =>
    gsap.to(content, {
      height: 0,
      duration: 0.3,
      ease: "power2.out",
      overwrite: true,
      onComplete: () => {
        // アニメーションが完了したら open 属性を削除
        element.removeAttribute("open");
      },
    });
  
  // リサイズ時にウィンドウの幅を確認して、768px以上ならis-openクラスを削除する
  let lastWindowSize = window.innerWidth; // 最後に保存されたウィンドウサイズ
  let resizeTimeout;
  
  window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
          if ((lastWindowSize < 768 && window.innerWidth >= 768) || (lastWindowSize >= 768 && window.innerWidth < 768)) {
              const openItems = document.querySelectorAll('.is-open');
              openItems.forEach(item => {
                  item.classList.remove('is-open');
              });
              location.reload(); // ページをリロード
          }
          lastWindowSize = window.innerWidth; // 現在のウィンドウサイズを保存
      }, 250);
  });
  
  // DOMが読み込まれたらアコーディオンの関数を実行
  document.addEventListener('DOMContentLoaded', accordionGsap());
}