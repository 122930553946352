import { accordion } from "./_accordion";
import { animation } from "./_animation";
import { common } from "./_common";
import { drawer } from "./_drawer";
import { slide } from "./_slide";
import { Password } from "./_password";


// ページの内容が完全に読み込まれた後のイベント
document.addEventListener('DOMContentLoaded', () => {
    // Common function execution
    [accordion, animation, common, drawer].forEach(fn => fn());

    // Main tag ID retrieval
    const PageId = document.querySelector('main')?.getAttribute('id');

    // Page-specific function execution
    const pageFunctions = {
        'top': [slide],
    };
    
    (pageFunctions[PageId] || []).forEach(fn => fn());
});

document.getElementById("passwordButton").addEventListener("click", function (event) {
    event.preventDefault(); // デフォルトのリンクの動作を無効にする
    Password(); // パスワード入力関数を呼び出す
});
