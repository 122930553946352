export function Password() {
    var pw = prompt("パスワードを入力してください：", "");
    if (pw !== null) { // ユーザーが「キャンセル」をクリックしたかどうかを確認
        // パスワードのバリデーションをここに追加
        if (validatePassword(pw)) {
            location = "http://u-tokyo-rad.jp/staff/Lec_file.pdf?password=" + pw;
        } else {
            alert("パスワードが間違っています");
        }
    }
}

function validatePassword(password) {
    // パスワードのバリデーションロジックをここに追加
    // 例えば、入力されたパスワードを事前に定義されたパスワードと比較するなど
    // 必要に応じてより複雑なバリデーションを行うこともできます
    return password === "Lec_file";
}