export function drawer() {
  window.addEventListener("load",function(){
    const root = document.querySelector(".js-drawerButton");
    const header = document.getElementById("js-header");
    const nav = document.getElementById("js-headerNav");
    const menuImg = document.querySelector(".p-header__nav-sp-list-icon--menu");
    const elementOpen = "is-open";
    root.addEventListener("click", () => {
        root.classList.toggle(elementOpen);
        header.classList.toggle(elementOpen);
        nav.classList.toggle(elementOpen);
        menuImg.classList.toggle(elementOpen);
    });
  }
)};
