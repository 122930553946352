export function slide() {
  var mySwiper = new Swiper('.swiper', {
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 4000,
  })
}
